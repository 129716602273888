import React from 'react';
import styled from 'styled-components';
import { useStaticQuery, graphql } from 'gatsby';

const StyledContainer = styled.div`
  padding: 60px 0;
  background: ${props => props.theme.colours.lightPink};
`;
const StyledSection = styled.div`
  padding: 0px 60px;
  max-width: 1403px;
  margin: auto;

  @media(max-width: ${props => props.theme.breakpoints.max.md}){
    padding: 0px 40px;
  }

  @media(max-width: ${props => props.theme.breakpoints.max.sm}){
    padding: 0px 24px;
  }
`;
const StyledTitle = styled.h2`
  font-style: normal;
  font-weight: bold;
  color: #1e1e1e;
  margin-bottom: 30px;
  @media (min-width: ${props => props.theme.breakpoints.min.md}) {
    margin-bottom: 45px;
    text-align: center;
  }
`;
const PointsContainer = styled.div`
  display: grid;
  justify-content: center;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 20px;
  @media (max-width: ${props => props.theme.breakpoints.max.md}) {
    display: block;
  }
`;

const ApplyContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (max-width: ${props => props.theme.breakpoints.max.md}) {
    flex-direction: row;
    margin-top: 16px;
    justify-content: flex-start;
  }
`;
const ApplyIconImage = styled.img`
  width: 50px;
  @media (max-width: ${props => props.theme.breakpoints.max.md}) {
    width: 35px;
  }
`;
const ApplyTitle = styled.h3`
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  color: #1e1e1e;
  margin-top: 16px;
  @media (max-width: ${props => props.theme.breakpoints.max.lg}) {
    font-size: 16px;
    line-height: 20px;
  }
  @media (max-width: ${props => props.theme.breakpoints.max.md}) {
    text-align: left;
    margin-left: 20px;
  }
`;
const ApplyInfo = () => {
  const data = useStaticQuery(graphql`
    {
      contentfulComponentsPage(pageId: { eq: "Nimble Anytime Landing" }) {
        components {
          ... on ContentfulNimbleSection {
            __typename
            id
            sectionId
            title
            components {
              ... on ContentfulComponentBasic {
                id
                html {
                  html
                }
                marginImage {
                  file {
                    url
                  }
                }
                heading
              }
            }
          }
        }
      }
    }
  `).contentfulComponentsPage.components.filter(
    item => item.__typename === 'ContentfulNimbleSection'
  );
  const applyData = data.filter(
    item => item.sectionId === 'Anytime Things to Apply'
  )[0];
  const { title: applyTitle, components: applyComponents } = applyData;
  return (
    <StyledContainer>
      <StyledSection>
        <StyledTitle>{applyTitle}</StyledTitle>
        <PointsContainer>
          {applyComponents.map(point => {
            return (
              <ApplyContainer key={point.heading}>
                <ApplyIconImage
                  src={point.marginImage.file.url}
                  alt={point.heading}
                />
                <ApplyTitle>{point.heading}</ApplyTitle>
              </ApplyContainer>
            );
          })}
        </PointsContainer>
      </StyledSection>
    </StyledContainer>
  );
};

export default ApplyInfo;
