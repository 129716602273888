/* eslint-disable react/prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import AnytimeBanner from './components/anytimeBanner.component';
import RepaymentCalculatorComplex from '../../../components/repaymentCalculatorComplex';
import Explainer from './components/explainer.component';
import ApplyInfo from './components/applyInfo.component';
import AppleApy from './components/applePay.component';
import TestimonialsComponent from '../../../components/testimonials';
import FAQComponent from '../../../components/faq';
import EndOfPage from '../components/endOfPage/endOfPage.component';
import { routes } from '../../../shared/constants'; 
import ProofPoints from '../../../components/proofPoints';

const NimbleAnytime = ({ componentData }) => {
  const anytimeBannerData = componentData.filter(
    item => item.__typename === 'ContentfulNimblePageHeadingSection'
  )[0];
  const testimonialsData = componentData.filter(
    item => item.__typename === 'ContentfulComponentTestimonials'
  )[0];
  const proofPointsData = componentData.filter(
    data => data.__typename === 'ContentfulComponentProofPoints'
  )[0];
  const faqsData = componentData.filter(
    item => item.__typename === 'ContentfulComponentFaq'
  )[0];
  const calculatorComplexData = componentData.filter(
    item => item.__typename === 'ContentfulNimbleRepaymentCalculatorComplex'
  )[0];
  const CLIENT_MIGRATION = {
    INITIAL_LOAD: 1,
    APPLY_NOW_PRESSED_API_CALL_FINISHED: 2
  };
  const [clientDetails, setClientDetails] = useState({});
  const [fetchClient, setFetchClient] = useState(false);
  const [migrationStep, setMigrationStep] = useState(CLIENT_MIGRATION.INITIAL_LOAD);
  const [clientUserId, setClientUserId] = useState({});

  const navigateAnytimeUrl = () => {        
    switch (migrationStep) {
      case CLIENT_MIGRATION.INITIAL_LOAD:
        break;
      case CLIENT_MIGRATION.APPLY_NOW_PRESSED_API_CALL_FINISHED:
        if (clientDetails && clientDetails.nimbleId) {
          switch(clientDetails.action)
          {
            case 'signin':
              // existing client redirect AnyTime->/sign-in page
              window.location.href = `${routes.global.urls.anyTime.signin}`;
              break;
            default:
              // existing client their details have been migrated redirect AnyTime->/welcome page
              window.location.href = `${routes.global.urls.anyTime.entry}?userId=${clientDetails.nimbleId}`
              break;
          }
        } else {
          // not an existing client redirect AnyTime->create-account page
          window.location.href = routes.global.urls.anyTimeNewAccount.entry;
        }
        break;
      default:
        break;
    }
  };

  const fetchMigrateOnDemandAsync = async () => {
    try {
      const requestOptions = {
        method: 'POST',
        headers: { Accept: 'application/json' },
        credentials: 'include'
      };
      const pathname = location.pathname;      
      const from = pathname.includes("/anytime/active-loan") ? 'active-loan-to-anytime':'apply';
      const response = await fetch(`${routes.global.urls.memberArea.entry}/Migrate/MigrateOnDemandAnytime?clientId=${clientUserId}&from=${from}`, requestOptions);

      if (response.ok) {
        return await response.json();
      }
    } catch (error) {
      // do we use a external logger?
    }

    return null;
  };
  
  useEffect(() => {
    navigateAnytimeUrl();
  }, [migrationStep]);

  useEffect(() => {
    const getClientDetailsAsync = async () => {
      const client = await fetchMigrateOnDemandAsync();

      setClientDetails(client);
      setMigrationStep(CLIENT_MIGRATION.APPLY_NOW_PRESSED_API_CALL_FINISHED);
      setFetchClient(false);
    };

    if (fetchClient) {
      getClientDetailsAsync();
    }
  }, [fetchClient]);
  
  useEffect(()=>{
    var client = document.cookie.replace(/(?:(?:^|.*;\s*)userId\s*\=\s*([^;]*).*$)|^.*$/, "$1");

    setClientUserId(client);
  }, []);

  const isAnytimeClient = () => {
    if (clientUserId) {
      setMigrationStep(CLIENT_MIGRATION.INITIAL_LOAD);
      setClientDetails({});
      setFetchClient(true);
    } else {
      // no userId redirect AnyTime->create-account page
      window.location.href = routes.global.urls.anyTimeNewAccount.entry
    }
  };

  return (
    <>
      <AnytimeBanner anytimeBannerData={anytimeBannerData} onClickApplyNow={isAnytimeClient} />
      {calculatorComplexData && <RepaymentCalculatorComplex data={calculatorComplexData} />}
      {proofPointsData && <ProofPoints data={proofPointsData} />}
      <ApplyInfo />
      {testimonialsData && <TestimonialsComponent data={testimonialsData} />}
      {faqsData && <FAQComponent data={faqsData} />}
      <EndOfPage applyName="Nimble Anytime" onClickApplyNow={isAnytimeClient} />
    </>
  );
};

export default NimbleAnytime;
